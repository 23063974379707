export const footerDataLinksProduct = [
  {
    id: 'presentation',
    link: '/files/livedune_presentation.pdf',
  },
  {
    id: 'reviews',
    link: 'https://startpack.ru/application/livedune/reviews',
  },
  {
    id: 'instagramStatistics',
    link: '/instagram-analytics',
  },
  {
    id: 'vkStatistics',
    link: '/vk-analytics',
  },
  {
    id: 'statistika-akkaunta-tik-tok',
    link: '/statistika-akkaunta-tik-tok',
  },
  {
    id: 'statistika-telegram',
    link: '/statistika-telegram',
  },
  {
    id: 'statistika-odnoklassniki',
    link: '/statistika-odnoklassniki',
  },
  {
    id: 'fbStatistics',
    link: '/statistika-facebook',
  },
]

export const footerDataLinksAbout = [
  {
    id: 'about',
    link: '/about',
  },
  {
    id: 'offer_new',
    link: '/files/new_terms.pdf',
  },
  {
    id: 'privacy',
    link: '/files/privacy.pdf',
  },
  {
    id: 'legalEntities',
    link: 'https://livedune.com/ru/blog/informaciya_dlya_yuridicheskih_lic',
  },
  {
    id: 'partners',
    link: '/partners',
  },
  {
    id: 'contacts',
    link: '/contacts',
  },
]

export const footerDataLinksHelpful = [
  {
    id: 'instagramAnalysis',
    link: '/instagram-competitors',
  },
  {
    id: 'instagramStoriesStatistics',
    link: '/instagram-stories-stats',
  },
  {
    id: 'downloadInstagramComments',
    link: '/download-comments-instagram',
  },
  {
    id: 'instagramAudit',
    link: '/audit-instagram',
  },
]

export const footerSocialLinks = [
  {
    title: 'instagram',
    link: 'https://www.instagram.com/livedune/',
  },
  {
    title: 'vk',
    link: 'https://vk.com/livedune',
  },
  {
    title: 'fb',
    link: 'https://www.facebook.com/livedune/',
  },
  {
    title: 'youtube',
    link: 'https://www.youtube.com/livedune',
  },
  {
    title: 'telegram',
    link: 'https://t.me/livedune',
  },
]
