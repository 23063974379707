import PropTypes from 'prop-types'
import { useRouter } from 'next/router'
import cns from 'classnames'

import { footerExceptionLinks } from '@data/footerExceptionLinks'

import $amplitude from '@utils/amplitude'
import { getFileLink } from '@utils/getFileLink'
import useFormatMessage from '@utils/useFormatMessage'
import { getLinkPrefix } from '@utils/getLinkPrefix'

import styles from './footer.module.scss'

export default function FooterList({ pageId, id, titleLink, listItems }) {
  const t = useFormatMessage()
  const { locale } = useRouter()
  const classModifier = pageId

  const handleFooterLinkClick = item => () => {
    $amplitude('[Landing] Footer Click', {
      page_name: pageId,
      item,
    })
  }

  return (
    <div className={styles.footer__list}>
      {!titleLink ? (
        <p className={styles.footer_list__title}>{t(`footer.titles.${id}`)}</p>
      ) : (
        <a
          className={cns(
            styles.footer_list__title,
            styles.footer_list__title__link
          )}
          href={getLinkPrefix(locale, titleLink)}
          onClick={handleFooterLinkClick(t(`footer.titles.${id}`))}
        >
          {t(`footer.titles.${id}`)}
        </a>
      )}
      <ul
        className={cns(
          styles.footer__list,
          classModifier && styles[`footer__list_${classModifier}`]
        )}
      >
        {listItems.map(item => {
          if (footerExceptionLinks[locale]?.includes(item.id)) {
            return null
          }

          return (
            <li key={`footer_list_${item.link}`}>
              <a
                className={cns(
                  styles.footer_list__link,
                  classModifier && styles[`footer_list__link__${classModifier}`]
                )}
                href={
                  getFileLink(locale, item.link) ||
                  getLinkPrefix(locale, item.link)
                }
                onClick={handleFooterLinkClick(
                  t(`footer.links.titles.${item.id}`)
                )}
                title={item.title}
                target="_blank"
                rel="noreferrer"
              >
                {t(`footer.links.titles.${item.id}`, {
                  span: text => <span>{text}</span>,
                })}
              </a>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

FooterList.propTypes = {
  pageId: PropTypes.string,
  id: PropTypes.string,
  titleLink: PropTypes.string,
  listItems: PropTypes.array,
}
