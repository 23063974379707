export const footerExceptionLinks = {
  en: ['vkStatistics', 'statistika-odnoklassniki', 'presentation', 'reviews', 'legalEntities'],
  br: [
    'vkStatistics',
    'presentation',
    'reviews',
    'legalEntities',
    'statistika-odnoklassniki',
  ],
  es: ['vkStatistics', 'statistika-odnoklassniki', 'presentation', 'reviews', 'legalEntities'],
}
