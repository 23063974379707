export const socialLinksData = [
  {
    title: 'instagram',
    link: 'https://www.instagram.com/livedune/',
  },
  {
    title: 'vk',
    link: 'https://vk.com/livedune',
  },
  {
    title: 'fb',
    link: 'https://www.facebook.com/livedune/',
  },
  {
    title: 'youtube',
    link: 'https://www.youtube.com/livedune',
  },
  {
    title: 'telegram',
    link: 'https://t.me/livedune',
  },
]
