import cns from 'classnames'
import { useRouter } from 'next/router'

import useFormatMessage from '@utils/useFormatMessage'

import styles from './breadcrumbs.module.scss'

export default function Breadcrumbs({ isFree = false, list }) {
  const t = useFormatMessage()
  const { locale } = useRouter()

  if (list && list.length) {
    return (
      <div className={styles.breadcrumbs}>
        <ul
          className={styles.breadcrumbs__list}
          itemScope
          itemType="https://schema.org/BreadcrumbList"
        >
          {list.map((item, index) => {
            const link =
              item.id === 'home' && locale !== 'en' ? `/${locale}` : item.link

            // формируем корректную ссылку для вложенных страниц
            const currentHref = isFree && link !== '/ru' ? `/${locale}/${link}` : link

            return (
              <li
                className={styles.breadcrumbs__item}
                key={`breadcrumbs_item_${index}`}
                itemProp="itemListElement"
                itemScope
                itemType="https://schema.org/ListItem"
              >
                <a
                  href={currentHref}
                  className={cns(styles.breadcrumbs__link, {
                    [styles.breadcrumbs__link__current]:
                      index + 1 === list.length,
                  })}
                  itemProp="item"
                >
                  <span className={styles.breadcrumbs__name} itemProp="name">
                    {t(`breadcrumbs.${item.id}`)}
                  </span>
                </a>

                <meta itemProp="position" content={index + 1} />

                <div className={styles.breadcrumbs__ico}>•</div>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }
  return null
}
