import useFormatMessage from '@utils/useFormatMessage'
import cns from 'classnames'

export default function FacebookMsg({ pageId }) {
  const t = useFormatMessage()
  const classModifier = pageId

  return (
    <div
      className={cns(
        'facebook--msg',
        classModifier && `facebook--msg_${classModifier}`
      )}
    >
      <div className={'wrapper'}>
        <strong>*</strong>
        {t(`footer.texts.facebookAlert`)}
      </div>
    </div>
  )
}
