import { useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'

import cns from 'classnames'

import { linksData } from '@data/linksData'
import { setCookies, getCookie, deleteAllCookies } from '@utils/coockies'
import { getFileLink } from '@utils/getFileLink'
import useFormatMessage from '@utils/useFormatMessage'
import $amplitude from '@utils/amplitude'

import styles from './coockies.module.scss'

export default function Cookies() {
  const t = useFormatMessage()
  const { locale } = useRouter()

  const cookiesRef = useRef()
  const [isShowModal, setIsShowModal] = useState(false)

  const handleClosePopUp = () => {
    setIsShowModal(false)
    $amplitude('[Landing] Pop-up Cookie Close')
  }

  const handleCancelCookies = () => {
    setIsShowModal(false)
    deleteAllCookies()
    $amplitude('[Landing] Pop-up Cookie Reject Button Click')
  }

  const handleApplyCookies = () => {
    setCookies('confirm_cookies', 'Y', '365', '/')
    setIsShowModal(false)
    $amplitude('[Landing] Pop-up Cookie Accept All Button Click')
  }

  useEffect(() => {
    if (cookiesRef.current) {
      const hasCookieConfirm = getCookie('confirm_cookies')
      if (!hasCookieConfirm) {
        setIsShowModal(true)
      }
    }
  }, [])

  return (
    <div
      className={cns(styles.cookies, {
        [styles.cookies__fade]: isShowModal,
        [styles.cookies__is_active]: isShowModal,
      })}
      ref={cookiesRef}
    >
      <div className={styles.cookies__closeButton} onClick={handleClosePopUp}>
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 13L13 1"
            stroke="#A6A9B5"
            strokeWidth="1.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13 13L1 1"
            stroke="#A6A9B5"
            strokeWidth="1.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>

      <p>
        {t('cookies.description')}&nbsp;
        <a
          href={getFileLink(locale, linksData.privacy)}
          target="_blank"
          rel="noreferrer"
        >
          {t('cookies.privacy')}.
        </a>
      </p>
      <div className={styles.button_container}>
        <button
          className={styles.cookies__applyCookie}
          onClick={handleApplyCookies}
        >
          {t('cookies.btn.apply')}
        </button>
        <button
          className={styles.cookies__cancelCookie}
          onClick={handleCancelCookies}
        >
          {t('cookies.btn.cancel')}
        </button>
      </div>
    </div>
  )
}
