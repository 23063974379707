import Head from 'next/head'
import Script from 'next/script'
import { useRouter } from 'next/router'

export default function HeadBasic({ title, description, ogTitle }) {
  const { locale, pathname } = useRouter()

  const url = locale !== 'en' ? `/${locale}${pathname}` : pathname

  return (
    <>
      <Head>
        <meta charSet="UTF-8" />

        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=5.0, viewport-fit=cover"
        />

        <meta httpEquiv="X-UA-Compatible" content="ie=edge" />

        <title>{title}</title>

        <meta name="description" content={description} />

        <link rel="preconnect" href="https://fonts.gstatic.com" />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />

        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />

        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />

        <link
          rel="canonical"
          href={`${process.env.BASE_URL}${url.replace(/\/+$/, '')}`}
        />

        <link rel="manifest" href="/site.webmanifest" />

        <meta name="msapplication-TileColor" content="#2d89ef" />

        <meta name="theme-color" content="#ffffff" />

        <meta property="og:url" content={`${process.env.BASE_URL}${url}`} />
        <meta property="og:title" content={ogTitle ?? title} />
        <meta property="og:description" content={description} />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={`https://livedune.com/images/livedune-og-${locale}.png`}
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="livedune.com" />
        <meta
          property="twitter:url"
          content={`${process.env.BASE_URL}${url}`}
        />
        <meta name="twitter:title" content={ogTitle ?? title} />
        <meta name="twitter:description" content={description} />
        <meta
          name="twitter:image"
          content={`https://livedune.com/images/livedune-og-${locale}.png`}
        />

        <link
          rel="alternate"
          hrefLang="pt"
          href={`https://livedune.com/br${pathname !== '/' ? pathname : ''}`}
        />
        <link
          rel="alternate"
          hrefLang="pt-br"
          href={`https://livedune.com/br${pathname !== '/' ? pathname : ''}`}
        />
        <link
          rel="alternate"
          hrefLang="ru"
          href={`https://livedune.com/ru${pathname !== '/' ? pathname : ''}`}
        />
        <link
          rel="alternate"
          hrefLang="uk"
          href={`https://livedune.com/ua${pathname !== '/' ? pathname : ''}`}
        />
        <link
          rel="alternate"
          hrefLang="en"
          href={`https://livedune.com${pathname !== '/' ? pathname : ''}`}
        />
        <link
          rel="alternate"
          hrefLang="ru-kz"
          href={`https://livedune.com/kz${pathname !== '/' ? pathname : ''}`}
        />
        <meta property="og:image:alt" content={title} />
      </Head>

      {/*Google Tag Manager*/}
      <Script
        id="google-tag-manager"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-KWTXQNR');`,
        }}
      ></Script>
      {/*End Google Tag Manager*/}

      {/*Google Tag Manager (noscript)*/}
      <noscript
        dangerouslySetInnerHTML={{
          __html: `<iframe src='https://www.googletagmanager.com/ns.html?id=GTM-KWTXQNR'
                          height='0' width='0' style='display:none;visibility:hidden'></iframe>`,
        }}
      ></noscript>
      {/*End Google Tag Manager (noscript)*/}

      {/* Google One Tap Auth */}
      <Script
        src={`https://accounts.google.com/gsi/client?v=${pathname}`}
        async
        defer
      />

      {/* Yandex.Metrika counter*/}
      <Script
        id="yandex-metrika"
        dangerouslySetInnerHTML={{
          __html: `(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)}; m[i].l=1*new Date(); for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }} k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)}) (window, document, "script", "https://cdn.jsdelivr.net/npm/yandex-metrica-watch/tag.js", "ym"); ym(24119476, "init", { clickmap:true, trackLinks:true, accurateTrackBounce:true, webvisor:false, ecommerce:"dataLayer" });`,
        }}
      ></Script>
      <noscript
        dangerouslySetInnerHTML={{
          __html: `<div><img src='https://mc.yandex.ru/watch/24119476' style='position:absolute; left:-9999px;' alt='' /></div>`,
        }}
      ></noscript>
      {/* End Yandex.Metrika counter*/}

      <Script src="/scripts/ldstart.js" defer />
    </>
  )
}
