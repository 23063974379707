import cns from 'classnames'

import styles from './section.module.scss'

export default function Section({
  children,
  className,
  marginedTop,
  marginedBottom,
  id = '',
}) {
  return (
    <section
      className={cns(
        marginedTop && styles.section_marginedTop,
        marginedBottom && styles.section_marginedBottom,
        className
      )}
      id={id}
    >
      {children}
    </section>
  )
}
