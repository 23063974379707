import { socialLinksData } from '@data/socialLinksData'

import Instagram from '@icons/instagram.svg'
import Vk from '@icons/vk.svg'
import Fb from '@icons/fb.svg'
import Youtube from '@icons/youtube.svg'
import Telegram from '@icons/telegram.svg'

import $amplitude from '@utils/amplitude'

import styles from './socialLinks.module.scss'

const iconsMap = {
  instagram: Instagram,
  vk: Vk,
  fb: Fb,
  youtube: Youtube,
  telegram: Telegram,
}

const Icon = ({ name, className }) => {
  const Component = iconsMap[name]
  return <Component className={className} />
}

export default function SocialLinks() {
  const handleClick = (socialName) => {
    $amplitude('[Landing] Link To Our Social Media', {social_media: socialName})
  }

  return (
    <ul className={styles.social}>
      {socialLinksData.filter(s => s.title !== 'youtube').map((socialLink, i) => (
        <li className={styles.social__item} key={i}>
          <a
            className={styles.social__link}
            href={socialLink.link}
            aria-label={socialLink.title}
            target="_blank"
            rel="noreferrer"
            onClick={() => handleClick(socialLink.title)}
          >
            <Icon name={socialLink.title} className={styles.social__ico} />
          </a>
        </li>
      ))}
    </ul>
  )
}
